import React from "react";
import { PageLayout } from "~components/templates/page-layout";
import { PipeDriveForm } from "~components/utils/pipedrive-form";
import * as styles from "./index.module.scss";

const CONTACT_FORM_ID =
  "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

const Component: React.FC = () => {
  return (
    <>
      <PageLayout
        paddingPattern="larger-than-header"
        meta={{
          title: "セキュリティ診断に関するお問い合わせ",
          description: "すぐにご対応いたしますので、お気軽にご連絡ください。",
        }}
      >
        <div className={styles.AssessmentContact}>
          <PipeDriveForm formID={CONTACT_FORM_ID} />
        </div>
      </PageLayout>
    </>
  );
};

export default Component;
